@import "Assets/styles/Global/_variables.scss";

.generated-link-container {
  @include flex($direction: column, $align: center, $justify: space-between);
  width: 30rem;
  @include for-mobile {
    @include full-content-width;
  }
  padding-top: 2rem;
  height: calc(100% - 10rem);
  margin: 0 auto;
  opacity: 0;
  animation: fade-in 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;

  img {
    height: 5rem;
    margin: 0 auto 1rem;
  }
  h1 {
    margin-bottom: 0;
    margin: 0.5rem 0 1.5rem;
  }

  .tabs {
    @include flex($direction: row, $align: center);
    background-color: $vg-gray-6;
    border-radius: 6px 6px 0px 0px;

    .tab {
      background-color: $vg-gray-6;
      color: $vg-gray-4;
      font-size: 0.65rem;

      &.active {
        background-color: $vg-aqua-main;
        color: $vg-white;
      }
      &:first-of-type {
        border-radius: 6px 0px 0px 0px;
      }
      &:last-of-type {
        border-radius: 0px 6px 0px 0px;
      }
      padding: 0 0.8rem;
    }
    .vertical-divider {
      width: 1px;
      height: 0.8rem;
      background-color: $vg-gray-5;
    }
  }
  .link-container {
    &.block {
      display: block;
    }
    width: 90%;
    background: rgba(71, 194, 181, 0.02);
    border: $border-aqua-main;
    border-radius: 5px;
    padding: 0.4rem 1rem;
    margin-bottom: 1rem;
  }
  .link-line,
  .link-container {
    @include flex($direction: row, $align: center, $justify: space-between);
    .vg-icon {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  p {
    &:last-of-type {
      color: $vg-gray-3;
    }
  }
  .horizontal-divider {
    height: 1px;
    width: 100%;
    background-color: $vg-gray-6;
    margin: 0.2rem auto;
  }
  .inline-button {
    display: inline-block;
    margin-left: 0.5rem;
  }
}
