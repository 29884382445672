@import "Assets/styles/Global/_variables";

.vg-machine-type-card {
  @include flex;

  background-color: $vg-white;
  border: $border-gray-6;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  height: 10rem;
  margin: 1rem 0.5rem 0;
  padding: 1rem 0 0;
  position: relative;
  width: 7rem;
  font-weight: $vg-font-weight-semi-bold;
  &.mobile-horizontal:not(.minimized) {
    @include for-mobile {
      flex-direction: row;
      height: 5.6rem;
      padding: 0 0 0 1rem;

      .price {
        font-size: 0.9rem;
        height: 100%;
        width: 5rem;
        border-radius: 0 $vg-border-radius-small $vg-border-radius-small 0;
      }
    }
  }

  &:hover {
    opacity: 1 !important;
  }

  &.selected {
    border: 1px solid;
    box-shadow: $medium-shadow;
    box-sizing: border-box;
    opacity: 1 !important;
  }

  &.not-selected:not(:hover):not(.disabled) {
    opacity: 0.8 !important;
  }

  .free-usage {
    @include flex;
    border: 1px solid;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-sizing: content-box;
    color: $vg-white;
    font-size: 0.6rem;
    height: 1.3rem;
    position: absolute;
    top: -1.3rem;
    width: 100%;
    @include for-mobile {
      display: none;
    }
  }

  .name {
    color: $vg-black;
    font-size: 0.8rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1rem;
    text-align: center;

    @include for-mobile {
      margin-bottom: -0.3rem;
      margin-top: 0.7rem;
    }
  }

  .type {
    @include flex;
    height: 50%;
    min-height: 4rem;
    flex: 0.4;
    .image {
      height: 80%;
    }
  }

  .details {
    @include flex;
    color: $vg-black;
    flex-grow: 1;
    font-size: 0.6rem;

    font-weight: $vg-font-weight-light;
    line-height: 0.9rem;

    @include for-mobile {
      padding-bottom: 0;
    }
  }

  .price {
    @include flex;
    border-radius: 0 0 $vg-border-radius-small $vg-border-radius-small;
    color: $vg-white;
    font-size: 0.6rem;
    height: 1.3rem;
    width: 100%;
    text-align: center;
  }
  .machine-card-radio-input {
    margin-right: 5rem;
  }
  &.minimized {
    margin: 0 0.5rem;
    height: auto;
    padding: 0.5rem 0 0;
    flex-grow: 0;
    flex-shrink: 0;
    max-height: 6.5rem;
    .details {
      justify-content: flex-start;
      padding: 0 0 1rem 0;
    }
    .price {
      height: auto;
    }
  }

  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }
}
