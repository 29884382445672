@import "Assets/styles/Global/_variables.scss";

.stream-header {
  height: 2rem;
  .edit-stream-name {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .title {
      input {
        font-size: $vg-font-size-tiny;
        font-weight: $vg-font-weight-bold;
      }

      &::placeholder {
        color: $vg-gray-5;
        font-size: 1.5rem;
        font-weight: $vg-font-weight-medium;
        line-height: 2.5rem;
        margin-bottom: 0.25rem;
      }
    }

    .edit-stream-button {
      right: 5%;

      &:hover {
        box-shadow: none !important;
      }
    }

    input {
      border-bottom: 1px solid $vg-gray-5;
    }
  }

  .stream-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
  }
}

.configure-stream {
  min-width: 450px;

  @include for-mobile {
    min-width: unset;
  }

  .configure-stream-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 7%;
    @include for-mobile {
      justify-content: unset;
      flex-direction: column;
    }
  }

  .sidebar {
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
    gap: 1.6rem;
    width: min-content;

    @include for-mobile {
      flex-direction: row;
      overflow-x: auto;
      width: auto;
      margin-bottom: 2rem;

      &::-webkit-scrollbar {
        height: 5px;
        width: 8px;
      }
    }
    .step-text {
      font-size: $vg-font-size-small;
      color: $vg-gray-3;

      &.selected {
        color: $vg-purple-main;
      }
    }
  }

  .configurations-container {
    @include flex($direction: column, $align: flex-start);
    padding: 2rem;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    animation: fade-in 1s;
    margin-bottom: 4rem;
    height: min-content;
    @include for-mobile {
      padding: unset;
    }
  }

  .configurations-basic {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    animation: fade-in 1s;
    height: min-content;
  }
  .configuration-option {
    width: 100%;
    animation: fade-in 1s;
    gap: 1rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.dropdown {
      @include flex($direction: row, $align: flex-start, $justify: flex-start);
      @include for-mobile {
        flex-direction: column;
      }
    }

    .header {
      font-size: $vg-font-size-medium;
      font-weight: $vg-font-weight-medium;
    }

    .vg-switch {
      p {
        font-size: $vg-font-size-medium;
        font-weight: $vg-font-weight-medium;
      }
    }

    .description {
      margin-top: 0.3rem;
      font-weight: $vg-font-weight-light;
      font-size: $vg-font-size-tiny;
      color: $vg-gray-3;
    }

    .alt-description {
      width: 70%;
      font-weight: $vg-font-weight-light;
      font-size: $vg-font-size-tiny;
      color: $vg-gray-4;
      line-height: 1.8;
      white-space: break-spaces;

      b {
        color: $vg-gray-2;
      }

      a {
        opacity: 0.6;
      }
    }

    .vg-dropdown {
      margin-left: auto;
      width: 100%;
      flex: 1;
    }

    .input-box {
      @include flex($direction: row, $align: center, $justify: flex-end);
      margin-left: auto;
      width: 14rem;
      min-width: 13rem;

      @include for-mobile {
        width: 100%;
      }

      color: $vg-gray-3;
      font-size: $vg-font-size-tiny;

      p {
        white-space: inherit;
        font-weight: $vg-font-weight-light;
      }

      .vg-input {
        width: 60%;
      }

      .vg-input textarea,
      .vg-input input {
        border-bottom: 1px solid $vg-gray-5;
        text-align: center;
        min-height: 1.5rem;
        margin-bottom: 10px;
      }
    }

    .vg-switch {
      justify-content: flex-end;

      p {
        margin-left: 8px;
      }
    }

    &.disabled {
      pointer-events: none;
      animation: none;
      opacity: 0.5;
    }

    &.switch-dropdown {
      width: 100%;
      @include flex($direction: row, $justify: flex-start, $align: flex-start);
      @include for-mobile {
        flex-direction: column;
      }

      .input-box {
        @include flex($direction: row, $align: center, $justify: space-around);
        margin-left: auto;
        width: 12rem;
        color: $vg-gray-3;
        font-size: $vg-font-size-tiny;
        @include for-mobile {
          margin-left: unset;
        }

        .vg-input {
          width: 3rem;
        }

        .vg-input textarea,
        .vg-input input {
          border-bottom: 1px solid $vg-gray-5;
          text-align: center;
          min-height: 1.5rem;
          margin-bottom: 10px;
        }

        .text-button-container {
          @include for-mobile {
            width: unset;
          }
        }
      }
    }

    .with-badge {
      @include flex($direction: row, $align: center, $justify: fex-start);
      width: 100%;

      .enterprise-badge {
        background-color: $vg-aqua-light;
        padding: 2px 8px;
        line-height: 1rem;
        border-radius: 14px;
        font-size: $vg-font-size-tiny;
        margin-left: 0.6rem;
        color: $white;
      }

      .pro-badge {
        background-color: $vg-baby-powder;
        padding: 2px 8px;
        line-height: 1rem;
        border-radius: 14px;
        font-size: $vg-font-size-tiny;
        margin-left: 0.6rem;
        color: $white;
      }
    }

    .input-box {
      @include for-mobile {
        margin-left: unset;
        min-width: unset;
      }

      .text-button-container {
        @include for-mobile {
          margin-left: unset;
        }
      }
    }
  }

  .capacity-number-input {
    text-align: center;
    width: 10%;
  }

  .vg-table-container {
    width: 100%;
    animation: fade-in 1s;
    height: min-content;
    margin-top: -1rem;

    .vg-input input {
      border-bottom: 1px solid $vg-gray-5;
      text-align: center;
      min-height: 1.5rem;
      margin-bottom: 0;
      color: $vg-gray-3;
      font-weight: $vg-font-weight-medium;
    }
  }

  .launch-step {
    animation: fade-in 1s;
    @include full-content-height;
    @include full-content-width;
    @include flex($direction: column, $align: center, $justify: center);

    .header-content {
      align-self: flex-start;
      margin-bottom: 2rem;
      .header {
        font-size: $vg-font-size-medium;
        font-weight: $vg-font-weight-medium;
      }

      .description {
        margin-top: 0.3rem;
        font-size: $vg-font-size-tiny;
        font-weight: $vg-font-weight-light;
        color: $vg-gray-3;
      }
    }

    &.disabled {
      pointer-events: none;
      animation: none;
      opacity: 0.5;
    }

    .arg-input-area {
      @include full-content-width;
      @include flex($direction: column, $align: stretch, $justify: center);
      gap: 1rem;
      min-height: 100%;
      margin: 1rem 0 4rem;
      @include for-mobile {
        gap: 1rem;
        justify-content: flex-start;
        padding-top: 1rem;
      }
      box-sizing: border-box;
      .vg-input {
        box-sizing: border-box;
        input {
          border: $border-gray-5;
          border-radius: 2px;
          color: $vg-gray-2;
        }
      }
      .footnote {
        @include for-not-mobile {
          white-space: break-spaces;
        }
        margin: 0 auto;
        text-align: center;
        color: $vg-gray-4;
        font-size: 0.7rem;
      }

      h3 {
        color: $vg-gray-1;
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
      }

      .link-container {
        @include flex($direction: row, $align: center, $justify: center);

        gap: 2rem;
        @include for-mobile {
          flex-direction: column;
          gap: 0.5rem;
        }
        .link-button {
          text-decoration: underline;
          font-weight: $vg-font-weight-regular;
          font-size: 0.75rem;
        }
      }
    }
    .set-button {
      margin-left: auto;
    }
  }

  .password-strength {
    height: auto;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $vg-gray-6;
    margin: 1rem 0;
  }
}

.submit-buttons-container {
  @include flex($direction: row, $align: center, $justify: space-around);
  box-sizing: border-box;
  background: linear-gradient(0deg, #ffffff 40%, rgba(255, 255, 255, 0) 80%);
  position: fixed;
  bottom: 0;
  width: calc(100vw - max(15vw, 220px));
  height: 4rem;
  padding-left: 16rem;
  padding-right: 4rem;
  gap: 40%;

  @include for-mobile {
    gap: unset;
    padding-left: unset;
    width: 100%;
    justify-content: flex-end;
    padding-right: unset;
    justify-content: center;
  }

  .reset-button {
    @include for-mobile {
      display: none;
    }
  }
  button {
    min-width: 12rem;
    font-size: $vg-font-size-tiny;
  }

  .vg-button {
    justify-content: center;
    gap: 4px;
  }
}

.port-access-tooltip-content {
  a {
    color: $vg-aqua-main;
  }
}

.close-icon {
  position: initial !important;
}
