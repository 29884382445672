@import "Assets/styles/Global/_variables.scss";

.slide-modal {
  height: 28rem;
  @include for-mobile {
    @include full-content-width;
    height: 32rem;
  }

  .modal-content {
    .modal-action-wrapper {
      width: 100% !important;
    }
    .description {
      width: 100%;
    }
  }

  .bullet-container {
    @include flex($direction: row, $align: center, $justify: center);
  }
  .slide-modal-footer {
    @include flex($direction: row, $align: center, $justify: space-between);
    @include full-content-width;
    position: absolute;
    bottom: 0;
    &.single-button {
      justify-content: flex-end;
    }
  }

  .bullet-container {
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    .bullet {
      height: 0.4rem;
      width: 0.4rem;
      border-radius: 100px;
      background-color: $vg-aqua-light-transparent;
      &.active {
        background-color: $vg-aqua-light;
      }
      &:not(:last-of-type) {
        margin-right: 0.2rem;
      }
    }
  }
  .slide-modal-footer-button {
    font-weight: $vg-font-weight-semi-bold;
    &.disabled {
      color: $vg-gray-4;
    }
  }
}
