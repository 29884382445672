@import "Assets/styles/Global/_variables.scss";

.vg-switch {
  @include flex($direction: row, $align: center, $justify: space-between);

  &.disabled {
    .switch {
      opacity: 0.4;
    }

    p {
      opacity: 0.4;
    }
  }

  &.alpha {
    p::after {
      font-family: $default-font-family;
      font-size: 10px;
      color: $vg-baby-powder;
      content: "Alpha";
      margin-left: 10px;
    }
  }

  .switch {
    @include flex($direction: row, $align: center, $justify: center);
    cursor: pointer;
    width: 40px;
    height: 20px;

    background: $vg-gray-4;
    border-radius: $vg-border-radius-small;
    position: relative;
    transition: background-color 0.2s;
  }

  &.checked {
    .switch {
      background: $vg-purple-main;
    }
  }

  &.reverse-text {
    flex-direction: row-reverse;
  }

  .switch-input {
    height: 0;
    width: 0;
    display: none;
  }

  .switch-button {
    content: "";
    position: absolute;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: $vg-border-radius-small;
    background: $white;
    transition: 0.2s;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .switch-input:checked + .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  .switch:active .switch-button {
    width: 20px;
  }

  p {
    margin-top: 2px;
  }

  .after-content {
    margin-left: 0.4rem;
  }
}
