@import "Assets/styles/Global/_variables.scss";

$input-font-size: 0.8rem;
$medium-input-font-size: 1.2rem;
$big-input-font-size: 1.5rem;
$error-padding: 0.4rem;

.vg-input {
  @include full-content-width;

  &::selection {
    background: $vg-purple-light; /* WebKit/Blink Browsers */
    height: 2rem;
    line-height: 2rem;
  }

  .input-wrapper {
    @include flex($justify: flex-end, $align: flex-start);
  }

  .input-line {
    @include full-content-width;
    position: relative;
  }

  .label {
    color: $vg-black;
    font-size: $input-font-size;
    font-weight: $vg-font-weight-medium;
    line-height: 1.4rem;
  }

  textarea,
  input {
    background-color: transparent;
    border: 0;
    border-bottom: $border-black;
    border-radius: 0;
    font-size: $input-font-size;
    line-height: $input-font-size;
    margin: 0;
    min-height: 2rem;
    outline: none;
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
    -webkit-appearance: none;

    &[type="password"]:not(.empty) {
      letter-spacing: 0.2rem;
    }

    &::placeholder {
      color: $vg-gray-3;
      font-size: $input-font-size;
    }

    &.big {
      font-size: $big-input-font-size;

      &::placeholder {
        font-size: $medium-input-font-size;
      }
    }

    &.iconed {
      padding: 0 2rem 0 0;
      width: calc(100% - 2rem);
    }

    &.centered {
      text-align: center;
    }

    &.white {
      border-color: $vg-white;
      color: $vg-white;

      &:active,
      &:focus {
        border-color: $vg-white;
      }

      &::placeholder {
        color: $vg-gray-5;
      }
    }

    &.gray-3 {
      border-color: $vg-gray-3;
      color: $vg-gray-3;
      &::placeholder {
        color: $vg-gray-5;
      }
    }
    &.gray-4 {
      border-color: $vg-gray-4;
      color: $vg-gray-4;
      &::placeholder {
        color: $vg-gray-4;
      }
    }

    &.gray-border {
      border-color: $vg-gray-5;
    }

    &:active,
    &:focus,
    &.error {
      &:not(.disabled) {
        border-bottom: $border-purple-main;
      }
    }

    &:disabled {
      background-color: $vg-white;
      color: $vg-gray-5;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  textarea {
    border: $border-gray-6;
    width: 100%;
    height: 150px;
    padding: 1rem 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
    line-height: 1rem;
  }

  .vg-icon {
    position: absolute;
    right: 0;
    top: 0.25rem;
  }

  .error-wrapper {
    @include flex();
    background-color: $vg-white;
    border-radius: 2px;
    bottom: 0.8rem;
    color: $vg-gray-2;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    font-size: 0.6rem;
    left: calc(100% + 1rem);
    min-height: 1.4rem;
    min-width: 7rem;
    padding: $error-padding;
    position: absolute;
    width: 10vw;

    &::after {
      border-color: transparent $vg-white transparent transparent;
      border-style: solid;
      border-width: $error-padding;
      content: "";
      margin-top: -$error-padding;
      position: absolute;
      right: 100%; /* To the left of the tooltip */
      top: 50%;
    }
  }

  .error-wrapper-mobile {
    display: none;

    @include for-mobile {
      color: $vg-purple-main;
      display: block;
      font-size: 0.7rem;
      line-height: 1.3rem;
      margin-top: 0.3rem;
    }
  }
}
