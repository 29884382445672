@import "Assets/styles/Global/_variables";

.app-streaming-settings {
  @include flex($direction: column, $align: flex-start);
  animation: fade-in 0.5s;
  animation-fill-mode: forwards;

  .account-row {
    @include flex($direction: row, $justify: flex-start, $align: flex-start);
    width: 100%;
    margin-bottom: 1rem;
    @include for-mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: unset;
      gap: 2rem;
    }

    .settings-container {
      width: 100%;
    }
    .users-header {
      @include flex($direction: row, $justify: space-between, $align: flex-start);
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
      }

      .with-badge {
        @include flex($direction: row, $justify: flex-start, $align: center);
      }
      .pro-badge {
        background-color: $vg-baby-powder;
        padding: 2px 8px;
        line-height: 1rem;
        border-radius: 14px;
        font-size: $vg-font-size-tiny;
        margin-left: 0.6rem;
        color: $white;
      }
      .user-count {
        font-size: 0.7rem;
        color: $vg-gray-3;
      }
    }
    .company-container {
      display: flex;
      width: 100%;
      .company-info {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        @include for-mobile {
          margin-left: 1rem;
          height: auto;
        }
      }
    }

    .user-container {
      width: 80%;
      @include for-mobile {
        width: 100%;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $vg-gray-5;
    margin: 2rem 0;
  }

  .streams-header {
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;

    p {
      @include for-mobile {
        margin-bottom: 0.4rem;
      }
    }

    @include for-mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    p:first-of-type {
      font-weight: $vg-font-weight-regular;
      font-size: $vg-font-size-medium;
      margin-bottom: 0.4rem;
    }

    .description {
      font-size: $vg-font-size-small;
      color: $vg-gray-3;
    }
  }

  .header {
    text-align: start;
    .title {
      font-weight: $vg-font-weight-regular;
      font-size: $vg-font-size-large;
      text-align: center;
    }
    .description {
      font-weight: $vg-font-weight-medium;
      font-size: $vg-font-size-small;
      text-align: center;

      .small {
        font-size: $vg-font-size-tiny;
        color: $vg-gray-3;
        display: inline-block;
      }

      .alt {
        font-weight: $vg-font-weight-light;
        font-size: $vg-font-size-tiny;
        color: $vg-gray-3;
        white-space: break-spaces;
      }
    }
    margin-top: 2rem;
    margin-bottom: 1rem;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .info-container {
    .info-label {
      color: $vg-gray-3;
      font-weight: $vg-font-weight-medium;
      font-size: 0.8rem;
      margin-bottom: 0.4rem;
      @include for-mobile {
        font-size: $vg-font-size-tiny;
      }
    }

    .info-value {
      font-weight: $vg-font-weight-light;
      font-size: 0.9rem;
      white-space: nowrap;
      @include for-mobile {
        font-size: $vg-font-size-tiny;
      }
    }

    .time-zone-value-container {
      display: flex;
      gap: 0.5rem;
      .info-value {
        font-size: 0.9rem;
      }
    }
  }

  .vendor-type-cards {
    @include flex($direction: row, $align: flex-start);
    gap: 1rem;

    @include for-mobile {
      width: 45rem;
    }

    .vendor-type-card {
      box-sizing: border-box;
      @include flex($direction: column, $align: center, $justify: flex-start);
      justify-content: flex-start;
      width: 16rem;
      height: 27rem;
      min-height: 100%;
      padding: 1.2rem;
      background-color: white;
      border-radius: $vg-border-radius-small;
      white-space: nowrap;
      border: 1px solid white;
      &.selected {
        border: 1.5px solid $vg-aqua-dark;
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: $vg-gray-5;
        margin: 0;
        margin-bottom: 1rem;
      }

      .image-container {
        img {
          height: 6rem;
          object-fit: cover;
        }
      }

      .benefits-section {
        @include flex($direction: column, $align: center, $justify: space-around);
        flex: 1;
      }

      .benefit {
        @include flex($direction: row, $align: flex-start);
        font-weight: $vg-font-weight-light;
        margin-bottom: 0.4rem;
        align-self: flex-start;
        font-size: $vg-font-size-tiny;
        color: $vg-gray-3;
        svg {
          margin-right: 0.4rem;
        }
      }
      button {
        margin-top: 1rem;
      }
    }
  }

  .vendor-subscription-error-modal {
    height: 28rem;
    box-sizing: border-box;

    .modal-content {
      align-items: center !important;
      justify-content: center !important;
    }

    h1 {
      font-size: $vg-font-size-large;
      font-weight: $vg-font-weight-semi-bold;
      line-height: 1.25rem;
      margin-bottom: 0.5rem;
    }

    .stream-error-message-container {
      margin-top: 1rem;
      white-space: pre-wrap;
    }
  }
  .vendor-card-container {
    width: calc(100% + 0.8rem);
    overflow-x: auto;
    display: flex;
  }
}
