@import "Assets/styles/Global/_variables.scss";

.new-stream-modal {
  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    color: $vg-black;
  }
  h3 {
    color: $vg-gray-2;
    font-weight: $vg-font-weight-semi-bold;
  }
  > p {
    color: $vg-gray-1;
  }
  .region-step {
    width: 100%;
    height: 13rem;
    .world-map {
      height: 16rem;
      margin-top: 1rem;
    }
  }
  .region-pricing-info {
    position: absolute;
    bottom: 2rem;
    width: 90%;
    margin: 0 auto;
    color: $vg-gray-3;
    font-size: 0.7rem;
    border: $border-gray-6;
    border-radius: 6px;
    padding: 0.8rem 2rem;
    @include for-mobile {
      padding: 0.5rem 0;
      width: auto;
    }
    opacity: 0;
    transition: opacity 1s;
    background-color: $vg-white;
    &.visible {
      opacity: 1;
    }
    @include flex($direction: row, $align: center, $justify: space-between);
    > div {
      text-align: center;
      width: 40%;
      line-height: 1.4;
      white-space: pre-wrap;
      span {
        span {
          font-weight: $vg-font-weight-bold;
        }
        p {
          line-height: 1rem;
        }
      }
    }
  }

  @include for-mobile {
    .world-map {
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
    }
  }

  .application-selection {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 18rem;
    &::-webkit-scrollbar {
      width: 0.4rem;
    }
  }

  .modal-sidebar-footer {
    display: flex;
    border: 1px solid $vg-gray-6;
    width: 8.5rem;
    border-radius: 6px;
    padding: 0.7rem;
    padding-left: 0.3rem;

    .vg-icon {
      width: 6rem;
      height: 1.5rem;
    }

    p {
      font-size: 0.65rem;
      color: $vg-gray-3;
      padding-left: 0.2rem;
      line-height: 1.8;
    }
  }
}
